<template>
  <svg
    viewBox="0 0 44 34"
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="34"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.4142 12.5857C32.1953 13.3667 32.1953 14.6331 31.4142 15.4141L23.4142 23.4141C22.6332 24.1952 21.3668 24.1952 20.5858 23.4141C19.8047 22.6331 19.8047 21.3667 20.5858 20.5857L28.5858 12.5857C29.3668 11.8046 30.6332 11.8046 31.4142 12.5857Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 3.99902C18.8403 3.99902 15.7362 4.83078 12.9998 6.41068C10.2634 7.99058 7.99111 10.263 6.41131 12.9994C4.83151 15.7359 3.99988 18.84 4 21.9997C4.00012 25.1594 4.83199 28.2635 6.412 30.9998C6.96433 31.9564 6.63665 33.1796 5.68009 33.7319C4.72353 34.2842 3.50033 33.9566 2.948 33C1.01688 29.6556 0.000147239 25.8618 1.59649e-08 21.9999C-0.000147088 18.138 1.0163 14.3441 2.94716 10.9995C4.87803 7.65495 7.65528 4.8776 10.9998 2.9466C14.3442 1.01561 18.1381 -0.000976562 22 -0.000976562C25.8619 -0.000976562 29.6558 1.01561 33.0002 2.9466C36.3447 4.8776 39.122 7.65495 41.0528 10.9995C42.9837 14.3441 44.0001 18.138 44 21.9999C43.9999 25.8618 42.9831 29.6556 41.052 33C40.4997 33.9566 39.2765 34.2842 38.3199 33.7319C37.3634 33.1796 37.0357 31.9564 37.588 30.9998C39.168 28.2635 39.9999 25.1594 40 21.9997C40.0001 18.84 39.1685 15.7359 37.5887 12.9994C36.0089 10.263 33.7366 7.99058 31.0002 6.41068C28.2638 4.83078 25.1597 3.99902 22 3.99902Z"
    />
  </svg>
</template>
